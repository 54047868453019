import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderFirst } from 'components/header-first';
import { HeaderFourth } from 'components/header-fourth';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { SocialMediaBanner } from 'modules/main-section/components/social-media-banner';
import { arrayOf, bool, node, string } from 'prop-types';

const SWrapper = styled.div`
    margin-top: 10.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: ${({ isMainPage }) => (isMainPage ? '3.125rem' : '4.625rem')};
    row-gap: 2.625rem;
    ${CONSTANTS.MEDIA.max1024`
        margin-top: 7rem;
        flex-direction: column;
        text-align: center;
    `}
`;

const STextWrapper = styled.div`
    position: relative;
    flex: 0.5;
    ${({ textWrapperCss }) => textWrapperCss};
`;

const SHeaderFirst = styled(HeaderFirst)`
    margin-bottom: 1.5rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 2.625rem;
    font-weight: 400;
    font-size: 1.75rem;
`;

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.2em;
    margin-bottom: 0.8rem;
`;

const SIconWrapper = styled.div`
    flex: 0.55;
    width: 100%;
    ${({ iconWrapperCss }) => iconWrapperCss};
`;

const SParagraph = styled(Paragraph)`
    font-size: 1.375rem;
    margin-bottom: 2.25rem;
`;

const SAnimatedText = styled.span`
    display: inline-block;
    width: 145px;
    text-align: center;
    margin: 0 0.5rem;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transform: translateY(${(props) => (props.visible ? '0' : '-20px')});
    transition: opacity 0.5s ease-in-out,
        transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--primary-color);
        opacity: 0.1;
        transform: scaleX(${(props) => (props.visible ? 1 : 0)});
        transform-origin: left;
        transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        z-index: -1;
    }
`;

export const MainSectionBase = ({
    buttonsSection,
    icon,
    titleId,
    subtitles,
    topSubTitleId,
    withSocialMedia,
    iconWrapperCss,
    isMainPage,
    paragraphs,
    textWrapperCss,
    isParagraphFormattedPreviously,
    isSubtitleFormattedPreviously,
}) => {
    const paragraphsToRender = paragraphs?.map((paragraph) => (
        <SParagraph key={paragraph}>
            {isParagraphFormattedPreviously ? (
                paragraph
            ) : (
                <FormattedMessage id={paragraph} />
            )}
        </SParagraph>
    ));

    const options = [' AI & LLM ', ' blockchain ', ' proptech ', ' fintech '];
    const [isTextVisible, setIsTextVisible] = React.useState(true);
    const [subtitleAdditionalText, setSubtitleAdditionalText] = React.useState(
        options[0],
    );

    React.useEffect(() => {
        let currentIndex = 0;

        const interval = setInterval(() => {
            setIsTextVisible(false); // Start fade out

            setTimeout(() => {
                currentIndex = (currentIndex + 1) % options.length;
                setSubtitleAdditionalText(options[currentIndex]);
                setIsTextVisible(true); // Start fade in
            }, 500);
        }, 3000); // Increased interval for better readability

        return () => clearInterval(interval);
    }, []);

    const subtitlesToRenderOnMainPage = subtitles?.length ? (
        <SHeaderSecond>
            {subtitles.map((subtitle, index) => (
                <React.Fragment key={subtitle}>
                    <span>
                        {isSubtitleFormattedPreviously ? (
                            subtitle
                        ) : (
                            <FormattedMessage id={subtitle} />
                        )}
                    </span>
                    {index === 0 && (
                        <SAnimatedText visible={isTextVisible}>
                            {subtitleAdditionalText}
                        </SAnimatedText>
                    )}
                </React.Fragment>
            ))}
        </SHeaderSecond>
    ) : null;

    const subtitlesToRender = subtitles?.length
        ? subtitles.map((subtitle, index) => (
              <SHeaderSecond key={subtitle}>
                  <span>
                      {isSubtitleFormattedPreviously ? (
                          subtitle
                      ) : (
                          <FormattedMessage id={subtitle} />
                      )}
                  </span>
              </SHeaderSecond>
          ))
        : null;

    return (
        <Container>
            <SWrapper isMainPage={isMainPage}>
                <STextWrapper textWrapperCss={textWrapperCss}>
                    {withSocialMedia && <SocialMediaBanner />}
                    {topSubTitleId && (
                        <SHeaderFourth>
                            <FormattedMessage id={topSubTitleId} />
                        </SHeaderFourth>
                    )}
                    <SHeaderFirst>
                        <FormattedMessage id={titleId} />
                    </SHeaderFirst>
                    {isMainPage
                        ? subtitlesToRenderOnMainPage
                        : subtitlesToRender}
                    {paragraphsToRender}
                    {buttonsSection}
                </STextWrapper>
                <SIconWrapper iconWrapperCss={iconWrapperCss}>
                    {icon}
                </SIconWrapper>
            </SWrapper>
        </Container>
    );
};

MainSectionBase.propTypes = {
    buttonsSection: node.isRequired,
    icon: node.isRequired,
    titleId: string.isRequired,
    subtitles: arrayOf(string),
    topSubTitleId: string,
    withSocialMedia: bool,
    isMainPage: bool,
    iconWrapperCss: arrayOf(string).isRequired,
    textWrapperCss: arrayOf(string),
    paragraphs: arrayOf(string),
    isDark: bool,
    isParagraphFormattedPreviously: bool,
};

MainSectionBase.defaultProps = {
    withSocialMedia: false,
    isMainPage: false,
    subtitles: [],
    topSubTitleId: '',
    paragraphs: [],
    isDark: false,
    textWrapperCss: [''],
    isParagraphFormattedPreviously: false,
};
